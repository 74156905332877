<template>
  <div>
    <login-card
      :name="appDisplayName"
      :logo="appLogoPrimary"
      :logo-class="appMenuLogoClass"
      :btn-label="$t('message.signin')"
      :link-label="$t('message.forgot_password')"
      :second-link-label="$t('signup.dont_have_account_yet')"
      :loading="loading"
      :signing-by-token="signingByToken"
      :account-selector="accountSelector"
      @click-btn="submitData(username, password)"
      @click-link="mode = 'reset'"
      @click-second-link="goToSignup"
    >
      <div v-if="!accountSelector && !signingByToken">
        <h1 class="text-h5 mb-5">{{ $t('message.signin') }}</h1>
        <v-text-field
          v-model="username"
          filled
          dense
          outlined
          autofocus
          type="email"
          name="login"
          data-test="login-email"
          :label="$t('message.email')"
          @keyup.enter.native="$refs.password.focus()"
        />

        <v-text-field
          id="password"
          ref="password"
          v-model="password"
          filled
          dense
          outlined
          type="password"
          name="password"
          data-test="login-password"
          :label="$t('message.password')"
          @keyup.enter.native="submitData(username, password)"
        />
      </div>
      <div v-if="accountSelector">
        <login-account-selector :accounts="accounts" @loginCanceled="loginCanceled" />
      </div>
      <div v-if="signingByToken">
        <v-progress-circular color="primary" :size="50" :width="3" indeterminate />
      </div>
    </login-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { AppError, clearString } from '@/utils';
import intercom from '@/services/intercom';
import Alerts from '@/mixins/Alerts';
import { loginUrl, userUrl, getHeader, clientId, clientSecret } from '@/config/config';
import {
  TWO_FA_ENABLED_LOGIN_CODE_ERROR,
  TWO_FA_LOGIN_FAIL_CODE_ERROR,
  TWO_FA_HEADER,
  TWO_FA_ERROR_MESSAGE_CODE,
} from '@/config/constants';
import LoginCard from '@/components/Login/LoginCard.vue';
import { homePage } from '@/router/paths';
import { useContracts } from '@/use/Marketplace/useContracts';
import {get as getCookie, remove as removeCookie} from '@/services/cookies'
import {COOKIES_KEYS} from "@/services/cookies/cookies.constants";

export default {
  components: {
    LoginAccountSelector: () => import(/* webpackChunkName: "login" */ './LoginAccountSelector.vue'),
    LoginCard,
  },
  mixins: [Alerts],

  props: {
    faCode: {
      type: String,
      default: '',
    },
    accessToken: {
      type: String,
      default: null,
    },
    refreshToken: {
      type: String,
      default: null,
    },
    redirect: {
      type: String,
      default: function() {
        return null;
      },
    },
  },

  setup(props, context) {
    const { contracts, fetchContracts } = useContracts({
      root: context.root,
    });

    return {
      contracts,
      fetchContracts,
    };
  },

  data() {
    return {
      loading: false,
      signingByToken: false,
      username: '',
      password: '',
      accountSelector: false,
      accounts: [],
    };
  },

  computed: {
    ...mapState({
      appName: state => state.app.name,
      appDisplayName: state => state.app.displayName,
      appLogoPrimary: state => state.app.images.logoPrimary,
      appMenuLogoClass: state => state.app.styles.menuLogoClass,
      appIntercomHide: state => state.app.intercom.hide,
      appIntercomId: state => state.app.intercom.id,
      appIntercomUserMaps: state => state.app.intercom.userMaps,
      appLocale: state => state.app.locale,
    }),
  },

  watch: {
    faCode(val) {
      !!val && this.submitData();
    },
    username(val) {
      this.$emit('username-changed', val.replace(/\s/g, ''));
    },
  },

  async created() {
    try {
      this.signingByToken = true;
      this.accessToken && this.refreshToken && (await this.getAuthUser(this.accessToken, this.refreshToken));
    } catch (e) {
      this.$toast.error(e.message);
    } finally {
      this.signingByToken = false;
    }
  },

  methods: {
    ...mapActions('app', ['loadConfigFromUser']),
    ...mapActions('layout', ['showAlert']),
    ...mapActions({
      setAuthUserData: 'account/setAuthUserData',
      setTwoFaStatus: 'account/setTwoFaStatus',
    }),

    ...mapMutations({
      setFeatureFlags: 'app/setFeatureFlags',
    }),

    async submitData() {
      this.loading = true;

      try {
        this.username = this.username.split(' ').join('');
        this.validateData(this.username, this.password);

        const { accessToken, refreshToken, useTwoFa } = (await this.getToken(this.username, this.password)) || {};

        if (useTwoFa) {
          this.loading = false;
          return;
        }

        accessToken && refreshToken && (await this.getAuthUser(accessToken, refreshToken));
      } catch (err) {
        this.$toast.error(err.message);
      }

      this.loading = false;
    },

    validateData(username, password) {
      const error = {
        color: 'warning',
        icon: 'mdi-account',
        message: '',
      };

      const exprMail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (password.trim() === '') {
        error.message = this.$t('errors.password_required');
        error.icon = 'mdi-lock';
      }

      if (username.trim() === '' || username.length < 5) {
        error.message = this.$t('errors.email_required');
      }

      if (!exprMail.test(username)) {
        error.message = this.$t('errors.email_invalid');
      }

      if (error.message !== '') {
        throw new AppError(error);
      }
    },

    async getToken(username, password) {
      try {
        const recaptchaToken = await this.$recaptcha('login');

        const response = await this.$http.post(
          loginUrl,
          {
            grant_type: 'password',
            client_id: clientId,
            client_secret: clientSecret,
            username: username,
            password: password,
            scope: '',
            recaptchaToken: recaptchaToken,
          },
          {
            headers: { [TWO_FA_HEADER]: this.faCode, 'Accept-Language': this.$i18n.locale },
          }
        );

        return {
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
        };
      } catch ({ status, body: { error } }) {
        return this.handlerGetTokenErrors(status, error);
      }
    },

    handlerGetTokenErrors(status, error) {
      if (
        (status === TWO_FA_ENABLED_LOGIN_CODE_ERROR || status === TWO_FA_LOGIN_FAIL_CODE_ERROR) &&
        error.code.includes(TWO_FA_ERROR_MESSAGE_CODE)
      ) {
        this.$emit('two-fa-login', { hasError: !!this.faCode });
        return {
          useTwoFa: true,
        };
      } else if (status === 401 || status === 403) {
        throw new AppError({
          color: 'error',
          icon: 'mdi-lock',
          message: error?.message || this.$t('errors.usr_pw_incorrect'),
        });
      }
    },

    async getAuthUser(accessToken, refreshToken) {
      let response;

      const authUser = {
        access_token: accessToken,
        refresh_token: refreshToken,
      };

      this.authUserSave(authUser);

      try {
        response = await this.$http.get(userUrl, { headers: getHeader() });
      } catch (e) {
        throw new AppError({
          color: 'error',
          icon: 'mdi-alert-circle',
          message: 'Login error, please try again',
        });
      }

      if (response.body.estado === 1) {
        this.authUserSave(this.authUserMapper(authUser, response.body));
        this.loadConfigFromUser();
        this.loadHelpServices();

        const {
          body: { otp_enabled: twoFaStatus, features },
        } = response || {};
        await this.setAuthUserData();
        await this.setTwoFaStatus(twoFaStatus);

        this.setFeatureFlags(features);

        if (features?.marketplace) {
          await this.fetchContracts(response.body.user.email);
        }

        if (!this.checkShowAccountSelector(response.body)) {
          this.redirectAfterLogin(response.body);
        }
      } else {
        localStorage.removeItem('auth_ehealth');

        throw new AppError({
          color: 'error',
          icon: 'mdi-account-outline',
          message:
            response.body.estado === 2
              ? this.$t('errors.trial_caducado', { name: this.appDisplayName })
              : this.$t('errors.usr_dado_baja'),
        });
      }
    },

    redirectAfterLogin(userData) {
      const lastPath = getCookie(COOKIES_KEYS.LAST_PATH);
      const pathRequiresAuth = JSON.parse(window.localStorage.getItem('pathRequiresAuth'));
      if (pathRequiresAuth) {
        window.location.replace(lastPath ? lastPath : pathRequiresAuth.fullPath);
        localStorage.removeItem('pathRequiresAuth');
      } else {
        this.goToHome(userData);
      }
    },

    checkShowAccountSelector() {
      const roles = JSON.parse(window.localStorage.getItem('auth_ehealth'))
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['roles']
        : [];
      const roleName = [
        this.$t('common.undefined'),
        this.$t('common.admin'),
        this.$t('common.professional'),
        this.$t('common.receptionist'),
        this.$t('common.rrhh'),
        this.$t('common.patient'),
      ];
      const items = [];
      roles.forEach(function(role) {
        items.push({
          title:
            role.company_name !== ''
              ? role.company_name
              : JSON.parse(window.localStorage.getItem('auth_ehealth'))['fullName'],
          subtitle: roleName[role.role_id],
          icon: role.icon,
          id: role.id,
          roleId: role.role_id,
        });
        items.push({ divider: true });
      });
      if (items.length > 0) {
        items.pop();
      }
      this.accounts = items;
      this.accountSelector = roles.length > 1;
      this.accountSelector && this.$emit('on-account-selector-visible');
      return roles.length > 1;
    },

    goToHome(data) {
      const routerPath = data.user.rol === 1 ? { name: 'Professionals' } : homePage();
      this.$router.push(routerPath);
    },

    load_i18n_message: function(folder) {
      const locale = this.$i18n.locale;
      const messages = this.$i18n.messages[locale];
      try {
        const trans_custom = require('@/core/i18n/' + folder + '/' + locale);
        const t = trans_custom.default;
        Object.keys(t).forEach(item => {
          if (typeof t[item] === 'object') {
            Object.keys(t[item]).forEach(trans => {
              if (messages[item]) {
                messages[item][trans] = t[item][trans];
              }
            });
          }
        });
        this.$i18n.setLocaleMessage(locale, messages);
      } catch (e) {
        this.$log.error(e);
      }
    },

    authUserMapper(authUser, data) {
      const isPatient = typeof data.patient !== 'undefined' && data.patient.id > 0;
      if (
        !isPatient &&
        typeof data?.empresa?.is_health_personnel !== 'undefined' &&
        data.empresa.is_health_personnel === 0
      ) {
        this.load_i18n_message('noHealthPersonnel');
      }

      const mappedUser= {
        ...authUser,
        email: data.user.email,
        name: data.user.name,
        fullName: !isPatient ? data.perfil.fullName : data.user.name + ' ' + data.user.surname,
        id: data.user.id,
        img: !isPatient ? data.perfil.img : '',
        lang: data.user.lang,
        nombreEmpresa: data?.empresa?.nombre || '',
        company_docline_api_id: data?.empresa?.docline_api_id || '',
        avatarEmpresa: data?.empresa?.avatar || '',
        logoEmpresa: data?.empresa?.logo || '',
        servicios_activos: !isPatient ? data.perfil.servicios_activos : [],
        rol: data.user.rol,
        roles: data.roles,
        user: data.user,
        trial: !isPatient ? data.perfil.trial : false,
        start_trial: !isPatient ? data.perfil.start_trial : '',
        formReserva: !isPatient ? data.perfil.formReserva : false,
        formPago: !isPatient ? data.perfil.formPago : false,
        formStepToStep: !isPatient ? data.perfil.stepToStep : false,
        permisos: !isPatient ? data.perfil.permisos : '',
        belong_to: !isPatient ? data.perfil.belong_to : 0,
        collegiateNumber:
          !isPatient && typeof data.perfil.collegiateNumber !== 'undefined' ? data.perfil.collegiateNumber : '',
        color: data?.empresa?.color || '',
        colorHexadecimal: data?.empresa?.colorHexadecimal || '',
        lastActivityTime: new Date(),
        permissions: !isPatient ? data.usuario.permissions : null,
        is_health_personnel: data?.empresa?.is_health_personnel || 1,
        is_collective: data?.empresa?.is_collective || 0,
        have_interconsultation: data?.empresa?.interconsultation === 1,
        erecipe: !isPatient && typeof data?.perfil?.erecipe !== 'undefined' ? data.perfil.erecipe : 0,
        hasSpecialtyToPrescribe:
          !isPatient && typeof data.perfil.hasSpecialtyToPrescribe !== 'undefined'
            ? data.perfil.hasSpecialtyToPrescribe
            : 0,
        company: {
          themeColorPrimary: data?.empresa?.themeColorPrimary || data?.empresa?.colorHexadecimal,
          themeColorSecondary: data?.empresa?.themeColorSecondary || data?.empresa?.colorHexadecimal,
          permissions: data?.empresa?.permissions || null,
          id: data?.empresa?.id || null,
          sharingReceptionist: data?.empresa?.sharingReceptionist,
          countryCode: data?.empresa?.country_code,
        },
        isSuperAdmin: !isPatient ? data.usuario.isSuperAdmin : false,
        payment_module: !isPatient ? data.usuario.payment_module : true,
        isPatient: isPatient,
        patient: isPatient ? data.patient : false,
        show_informed_consent: data?.empresa?.informed_consent || 0,
        stripeConfigured:
          !isPatient && typeof data.perfil.stripeConfigured !== 'undefined' ? data.perfil.stripeConfigured : 0,
        environment: data?.environment || [],
      };

      if (data.user.rol === 2) {
        mappedUser.specialtyName = data?.usuario?.professional_specialty_name
      }

      return mappedUser;
    },

    authUserSave(authUser) {
      window.localStorage.setItem('auth_ehealth', JSON.stringify(authUser));
    },

    goToSignup() {
      this.$router.push('/signup');
    },

    loadHelpServices() {
      const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));

      let user = {};
      let showIntercom = false;
      if (authUser?.id) {
        user = { ...authUser };
        const name_env = this.appName;
        const user_id = user.id;
        user.id = this.appName + '_' + user.id;
        if (
          name_env.toLowerCase() === 'uniteco' &&
          typeof user.nombreEmpresa !== 'undefined' &&
          user.nombreEmpresa !== ''
        ) {
          user.id = clearString(user.nombreEmpresa) + '_' + user_id;
        }
        showIntercom = true;
      }

      if (this.appIntercomHide !== true && showIntercom) {
        !authUser?.isPatient && intercom(this.appIntercomId, this.appIntercomUserMaps, user);
      }
    },

    loginCanceled() {
      localStorage.removeItem('auth_ehealth');
      this.accounts = [];
      this.accountSelector = false;
    },
  },
};
</script>
